import { template as template_aab5015960264e8191068adf91be660e } from "@ember/template-compiler";
import { t } from 'ember-intl';
import TooltipWithIcon from '../../ui/tooltip-with-icon';
export default template_aab5015960264e8191068adf91be660e(`
  <span class="evolution-header">

    {{t "pages.campaign-results.table.column.evolution"}}

    <TooltipWithIcon
      @iconName="help"
      @content={{@tooltipContent}}
      @ariaHiddenIcon={{true}}
      class="tooltip-with-icon-small"
    />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
