import { template as template_de912033287748dcbf9e2d783a170a77 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_de912033287748dcbf9e2d783a170a77(`
  <PixIndicatorCard
    @title={{t "cards.participants-average-results.title"}}
    @iconName="awards"
    @color="blue"
    @info={{t "cards.participants-average-results.information"}}
    @isLoading={{@isLoading}}
    class="indicator-card"
    ...attributes
  >
    <:default>{{t "common.result.percentage" value=@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
