import { template as template_c5ac9ffe5a0841edaba907c367574086 } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class CampaignArchivedBanner extends Component {
    @service
    store;
    @service
    notifications;
    @service
    intl;
    @service
    currentUser;
    @action
    async unarchiveCampaign() {
        try {
            const campaign = this.store.peekRecord('campaign', this.args.campaign.id);
            await campaign.unarchive();
        } catch  {
            this.notifications.sendError(this.intl.t('api-error-messages.global'));
        }
    }
    get displayUnarchiveButton() {
        const isCurrentUserAdmin = this.currentUser.prescriber.isAdminOfTheCurrentOrganization;
        const isCurrentUserOwnerOfTheCampaign = parseInt(this.currentUser.prescriber.id) === this.args.campaign.ownerId;
        const isCurrentUserAllowedToUnarchiveCampaign = isCurrentUserAdmin || isCurrentUserOwnerOfTheCampaign;
        return isCurrentUserAllowedToUnarchiveCampaign;
    }
    static{
        template_c5ac9ffe5a0841edaba907c367574086(`
    {{#if @campaign.isArchived}}
      <div class="campaign-archived-banner">
        <div class="campaign-archived-banner__text">
          <PixIcon class="campaign-archived-banner__icon" @name="inventory" @plainIcon={{true}} />
          <span>{{t "pages.campaign.archived"}}</span>
        </div>
        {{#if this.displayUnarchiveButton}}
          <PixButton @triggerAction={{this.unarchiveCampaign}} @variant="transparent-dark" @isBorderVisible={{true}}>
            {{t "pages.campaign.actions.unarchive"}}
          </PixButton>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
