import { template as template_e8e01e417083410391c5b6dc06a8a290 } from "@ember/template-compiler";
import Certification from './certification';
import LanguageAvailability from './language-availability';
import Scommunication from './sco-communication';
import Survey from './survey';
export default template_e8e01e417083410391c5b6dc06a8a290(`
  <div class="top-banners">
    <Certification />
    <Scommunication />
    <LanguageAvailability />
    <Survey />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
