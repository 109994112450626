import { template as template_bb4ddf0f55004a688dc6a882f1a51a81 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
export default template_bb4ddf0f55004a688dc6a882f1a51a81(`
  <div class="participants-by-stage__graph" role="button" {{on "click" (fn @onClickBar @stageId)}} ...attributes>
    <div class="participants-by-stage__bar" style={{@barWidth}} aria-hidden="true"></div>
    <div class="participants-by-stage__percentage">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
