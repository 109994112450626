import { template as template_950ef6683f1b46008f26582953617221 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_950ef6683f1b46008f26582953617221(`
  <p class="screen-reader-only">{{t "charts.participants-by-stage.loader"}}</p>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
