import { template as template_d820e6446d9b4a94b327f954d531b1bf } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_d820e6446d9b4a94b327f954d531b1bf(`
  <section class="no-campaign-panel panel">
    <img src="{{this.rootURL}}/images/empty-state.svg" alt="" role="none" />

    <p class="no-campaign-panel__information-text hide-on-mobile">
      {{t "pages.campaigns-list.no-campaign"}}
    </p>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
