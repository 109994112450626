import { template as template_afc3b9b7c9a9456eb10dd697890c4ebd } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_afc3b9b7c9a9456eb10dd697890c4ebd(`
  <p class="screen-reader-only">{{t "charts.participants-by-stage.loader"}}</p>
  <div class="participants-by-status__loader" aria-hidden="true">
    <span class="participants-by-status__loader--chart placeholder-doughnut"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
