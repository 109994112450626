import { template as template_f82d32e9e5bb4e7ab4e548126aba56a9 } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_f82d32e9e5bb4e7ab4e548126aba56a9(`
  {{#if (gt @occupied @total)}}
    <PixNotificationAlert @type="error" @withIcon={{true}}>
      {{t "banners.over-capacity.message"}}
    </PixNotificationAlert>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
